<template>
  <el-dialog :visible.sync="dialogShow" :modal-append-to-body='false' :before-close="handleClose">
  <div class="orderDetailWrap">
    <el-steps v-if="orderData.applyType===1" :active="activeIndex" finish-status="success"	 :process-status="orderData.status===4?'error':orderData.status===3?'success':'wait'">
      <el-step title="用户申请" :description="orderData.applyTime"></el-step>
      <el-step title="处理申请" :description="orderData.dealTime?orderData.dealTime:''"></el-step>
      <el-step  title="用户退回"></el-step>
      <el-step title="售后完成" v-if="orderData.status!==3&&orderData.status!==4"></el-step>
      <el-step title="退款成功" v-if="orderData.status===3" :description="orderData.dealTime"></el-step>
      <el-step title="拒绝退款" :description="orderData.remarks" v-if="orderData.status===4"></el-step>
    </el-steps>
    <el-steps v-else :active="activeIndex" finish-status="success"	 :process-status="orderData.status===4?'error':orderData.status===3?'success':'wait'">
      <el-step title="用户申请" :description="orderData.applyTime"></el-step>
      <el-step title="处理申请" :description="orderData.dealTime?orderData.dealTime:''"></el-step>
      <el-step title="售后完成" v-if="orderData.status!==3&&orderData.status!==4"></el-step>
      <el-step title="退款成功" v-if="orderData.status===3" :description="orderData.dealTime"></el-step>
      <el-step title="拒绝退款" :description="orderData.remarks" v-if="orderData.status===4"></el-step>
    </el-steps>
    <div>
      <div>
        <div style="font-weight: bold;font-size: 17px;margin:10px 0">收货信息</div>
        <div style="margin-top: 10px">
          {{orderData.name}}<span style="margin-left: 20px">{{orderData.phone}}</span>
        </div>
        <div style="margin-top: 10px">
          {{orderData.addressLocation + orderData.addressDetail}}
        </div>
      </div>
    </div>
    <template>
      <div class="companyBox" style="margin-top: 20px;">
        <el-table :data="orderData.itemList" style="width: 100%">
          <el-table-column prop="goodsName" label="商品名称" align="center">
          </el-table-column>
          <el-table-column label="商品图片" align="center">
            <template slot-scope="scope">
              <img :src="baseImgUrl + scope.row.img" class="goodsPhoto" />
            </template>
          </el-table-column>
          <el-table-column prop="specName" label="商品规格" align="center">
          </el-table-column>
          <el-table-column prop="specName" label="商品价格" align="center">
            <template slot-scope="scope">
              <template>
                {{ scope.row.price | formatAmount}}
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="购买/申请数量" align="center">
            <template slot-scope="scope">
               {{scope.row.num}}/{{scope.row.applyNum}}
            </template>
          </el-table-column>
        </el-table>
        <!-- </div> -->
      </div>
    </template>

    <div>
      <div class="item"><span class="title">货物状态:</span><span class="content">{{orderData.received?'已收到货':'未收到货'}}</span></div>
      <div class="item"><span class="title">退款方式:</span><span class="content">{{orderData.applyType===0?'仅退款':'退货退款'}}</span></div>
      <div class="item"><span class="title">退款原因:</span><span class="content">{{orderData.reasonType}}</span></div>
      <div class="item"><span class="title">购买/退款金额:</span><span class="content">¥{{buyPrice | formatAmount}}/¥<span style="color: red;font-size: 20px">{{orderData.amount| formatAmount}}</span></span></div>
      <div class="item" v-if="orderData.userRemark"><span class="title">备注:</span><span class="content">{{orderData.userRemark}}</span></div>
    </div>

    <div class="orderPayBox" v-if="orderData.status===2">
      <el-button type="primary" @click="agree">同意退款</el-button>
      <el-button type="danger" @click="refund">拒绝退款</el-button>
    </div>
    <div class="orderPayBox" v-if="orderData.status===9">
      <el-button type="primary" @click="receiveGood">确认收货</el-button>
    </div>
  </div>
  </el-dialog>
</template>
<script>
import {
  baseImgUrl
} from '@/utils/constant'
import {
  fetchOrderDetail
} from '@/api/order'
import {
  formatAmount
} from '@/utils/utils'
import {get, post} from "@/utils/request";
export default {
  name: "orderDetail",
  data() {
    return {
      ordersId: null,
      orderData: [],
      baseImgUrl: baseImgUrl,
      orderStateText: '',
      buyPrice: 0,
      activeIndex: 0
    }
  },
  props:{
    'dialogShow':{
       type: Boolean
    }
  },
  components: {

  },
  mounted() {
    this.init()
  },
  filters: {
    formatAmount(amount) {
      return formatAmount(amount)
    },
  },
  methods: {
    init(orderId) {
      this.ordersId = orderId
      let v = this
      if (this.ordersId) {
        get('/orders/refundDetailPc',{orderId:this.ordersId}).then(res=>{
          this.orderData=res
          this.getBuyPrice()
          this.initActiveIndex()
        })
      }
    },
    handleClose() {
      this.$emit('close')
    },
    initActiveIndex(){
        if(this.orderData.status===2){
          this.activeIndex=1
        }else if(this.orderData.status===4||this.orderData.status===3||this.orderData.status===8){
          // if(this.orderData.applyType===1){
          //   this.activeIndex=4
          // }else {
            this.activeIndex=3
          // }
        }else if(this.orderData.status===9){
          this.activeIndex=3
        }
    },
    agree(){
      let message="用户申请的为仅退款,此操作将直接退款给用户且不可撤销, 是否继续?"
      if(this.orderData.applyType===1){
         message="同意用户退货,此操作不可撤销, 是否继续?"
      }
      this.$confirm(message, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          post('/orders/agreeRefund',{orderRefundId:this.orderData.id}).then(res=>{
             this.activeIndex=this.activeIndex+1
             if(this.orderData.applyType===1){
               this.orderData.status=8
             }else {
               this.orderData.status=3
             }
             this.handleClose()
          })
      })
    },
    refund(){
      var that = this
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
          post('/orders/refuseRefund',{orderRefundId:this.orderData.id,remark: value}).then(res=>{
            console.log(res)
            that.handleClose
          })
      })
    },
    receiveGood(){
      this.$confirm("请确认已收到退货,此操作将直接退款给用户且不可撤销, 是否继续?", '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        post('/orders/finishRefund',{orderRefundId:this.orderData.id}).then(res=>{
          this.activeIndex=this.activeIndex+1
          this.orderData.status=3
          this.handleClose()
        })
      })
    },
    getBuyPrice(){
      let allPrice=0;
      this.orderData.itemList.forEach(item=>{
        allPrice+=item.price*item.num
      })
      this.buyPrice=allPrice
    },
    goWuliu(num) {
      window.open(`https://www.kuaidi100.com/?nu=${num}`)
    },
    getOrderState(orderState, refundState) {
      let str = ''
      let stateArr = ['已过期', '待付款', '待发货', '待收货', '已完成', '交易取消', '售后完成', '退款申请中']
      let refundArr = [{
        num: 1,
        text: '未申请退款'
      },
        {
          num: 2,
          text: '退款申请中',
        },
        {
          num: 3,
          text: '退款申请成功',
        },
        {
          num: 4,
          text: '取消退款'
        },
        {
          num: 8,
          text: '待退货',
        },
        {
          num: 9,
          text: '退货中',
        },
      ]
      if (refundState === 1) {
        str = stateArr[orderState]
      } else {
        str = refundArr.filter(item => item.num === refundState)[0].text
      }
      return str
      // BE_OVERDUE(0,"已过期"),
      // WAIT_PAYMENT(1,"待付款"),
      // WAIT_DELIVER(2,"待发货"),      可退款，并且refundState 1/4
      // WAIT_RECEIVING(3,"待收货"),  可退款  , 并且refundState 1/4                  确认收货等按钮加一层判断 refundState === 1， 再显示
      // COMPLETE(4,"已完成"),       可退款(7天无理由)
      // CANCEL(5,"交易取消"),
      // REFUND_SUCCESS(6,"售后完成")
      // REFUND_APPLY(7,"退款申请中"),

      // NO_REFUND(1,"未申请退款"),
      // REFUND_APPLY(2,"退款申请中"),
      // REFUND_SUCCESS(3,"退款申请成功"),
      // REFUND_ERROR(4,"拒绝退款"),
      // REFUND_CANCEL(5,"取消退款"),
      // WAIT_EXPRESS(8,"待退货"), ？
      // EXPRESSING(9,"退货中"); ？

    }
  }
}
</script>
<style scoped>
.goodsPhoto {
  width: 60px;
  height: 60px;
}
.item{
  position: relative;
  margin-top: 10px;
}
.title{
  font-weight: bold;
}
</style>
<style>





.companyName {
  border: 1px solid #ccc;
  height: 40px;
  line-height: 40px;
  background: #fff;
  margin-top: 10px;
}

.red {
  color: #f00;
}

.orderDetailWrap {
  background-color: #fff;
  padding: 20px;
}

.orderPayBox {
  text-align: right;
  font-size: 13px;
  margin: 20px;
}

.orderPayBox > div {
  padding: 5px 0;
}
</style>
