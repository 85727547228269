<template>
  <el-dialog :visible="dialogShow" :before-close="()=>{$emit('close')}" :modal-append-to-body='false'>
    <div class="orderDetailWrap">
      <div style="padding: 0 20px;">
        <div style="display: flex;align-items: center;padding: 10px;">
          <span style="font-size: 20px;font-weight: bold;">订单详情</span>
          <span
              style="display: inline-block;padding: 5px 10px;color: #F80F04;border: 1px solid #F80F04;background: rgba(248, 15, 4, 0.1);border-radius: 20px;font-size: 12px;margin-left: 20px;">{{orderStateText}}
				</span>
        </div>
        <div style="padding: 10px;display: flex;justify-content: space-between;border-bottom: 1px solid #E8EBF1;">
          <div style="padding: 10px 0;display: flex;justify-content: flex-start;">
            <div style="font-size: 13px;"><span style="color: #898E98;">订单号:
						</span><span>{{orderData.ordersNo}}</span></div>
            <div style="font-size: 13px;margin-left: 60px"><span style="color: #898E98;">创建时间:
						</span><span>{{ orderData.createTime}}</span></div>
          </div>
          <!-- <div
            style="padding: 5px 40px;border-radius: 5px;color: #fff;background: #51CBCD;font-weight: bold;font-size: 13px;display: flex;align-items: center;">
            退 款</div> -->
        </div>
        <div>
          <div style="font-weight: bold;font-size: 17px;padding: 10px;">收货信息</div>
          <div style="font-size: 13px;padding: 5px 10px;"><span
              style="color: #898E98;text-align-last:justify;display: inline-block;width: 60px;">姓名:
					</span><span>{{orderData.name}}</span>
          </div>
          <div style="font-size: 13px;padding: 5px 10px;"><span
              style="color: #898E98;text-align-last:justify;display: inline-block;width: 60px;">联系电话:
					</span><span>{{orderData.phone}}</span>
          </div>
          <div style="font-size: 13px;padding: 5px 10px;"><span
              style="color: #898E98;text-align-last:justify;display: inline-block;width: 60px;">收货地址:
					</span><span>{{orderData.addressLocation + orderData.addressDetail}}</span>
          </div>
        </div>
        <!-- <div>快递公司：{{orderData.express}}</div> -->
        <!-- <div>快递单号: {{orderData.expressNum}}
          <el-button size="mini" @click="goWuliu(orderData.expressNum)">查询</el-button>
        </div> -->
        <!-- <div>订单号: {{orderData.ordersNo}}</div>
        <div>订单状态: <span class="red">{{ orderStateText }}</span></div>
        <div>创建时间: {{ orderData.createTime}}</div>
        <div>收货人: {{orderData.name}}</div>
        <div>电话: {{orderData.phone}}</div>
        <div>收货地址: {{ orderData.addressLocation + orderData.addressDetail}}</div> -->
      </div>
      <template v-if="orderData.companyItemList && orderData.companyItemList.length">
        <div class="companyBox" style="margin-top: 20px;" v-for="companyItem in orderData.companyItemList" :key="companyItem.companyId">
          <!-- <div class="companyName"><img src="" />公司名称：{{ companyItem.companyName }}</div> -->
          <!-- <div class="goodsBox" v-for="goodItem in companyItem.skuGoodVoList" :key="goodItem.goodsId"> -->
          <el-table :data="companyItem.skuGoodVoList" style="width: 100%">
            <el-table-column prop="goodsName" label="商品名称" align="center">
            </el-table-column>
            <el-table-column label="商品图片" align="center">
              <template slot-scope="scope">
                <img :src="baseImgUrl + scope.row.img" class="goodsPhoto" />
              </template>
            </el-table-column>
            <el-table-column prop="specName" label="商品规格" align="center">
            </el-table-column>
            <el-table-column prop="specName" label="单价*数量" align="center">
              <template slot-scope="scope">
                <template>
                  {{ scope.row.price | formatAmount}}*{{scope.row.num}}
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="subAmount" label="优惠金额" align="center">
              <template slot-scope="scope">
                {{scope.row.subAmount| formatAmount}}
              </template>
            </el-table-column>
            <el-table-column prop="subAmount" label="商品状态" align="center">
              <template slot-scope="scope">
                {{getGoodState(scope.row.state)}}
              </template>
            </el-table-column>
            <el-table-column prop="allPrice" label="实付金额" align="center">
              <template slot-scope="scope">
                 {{scope.row.allPrice| formatAmount}}
              </template>
            </el-table-column>
            <el-table-column prop="adminAmount" label="预计收入" align="center">
              <template slot-scope="scope">
                {{scope.row.adminAmount| formatAmount}}
              </template>
            </el-table-column>
          </el-table>
          <!-- </div> -->
        </div>
      </template>
      <div class="orderPayBox">
        <div>
          <span style="color: #898E98;">应付金额：</span><span>￥{{ orderData.orderPrice | formatAmount}}</span>
        </div>
        <div>
          <span style="color: #898E98;">实付金额：</span><span>￥{{ orderData.payPrice | formatAmount}}</span>
        </div>
        <!-- <div>
          <span style="color: #898E98;">结算总计：</span><span>￥{{ orderData.orderPrice | formatAmount}}</span>
        </div> -->
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  baseImgUrl
} from '@/utils/constant'
import {
  fetchOrderDetail
} from '@/api/order'
import {
  formatAmount
} from '@/utils/utils'
export default {
  name: "orderDetail",
  props:{
    'dialogShow':{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ordersId: null,
      orderData: [],
      baseImgUrl: baseImgUrl,
      orderStateText: ''
    }
  },
  components: {

  },
  mounted() {
    this.init()
  },
  filters: {
    formatAmount(amount) {
      return formatAmount(amount)
    },
  },
  methods: {
    init(orderId) {
      this.ordersId = orderId
      let v = this
      if (this.ordersId) {
        let params = {
          orderId: this.ordersId
        }
        fetchOrderDetail(params).then(res => {
          if (res.data) {
            v.orderData = res.data
            v.orderStateText = v.getOrderState(v.orderData.orderState, v.orderData.refundState)
            console.log('orderData', v.orderData)
          }
        })
      }
    },
    getGoodState(state) {
      if(state && state === 7) {
        return '退款申请中'
      }else if(state && state === 6){
        return '售后完成'
      }
      return '正常'
    },
    goWuliu(num) {
      window.open(`https://www.kuaidi100.com/?nu=${num}`)
    },
    getOrderState(orderState, refundState) {
      let str = ''
      let stateArr = ['已过期', '待付款', '待发货', '待收货', '已完成', '交易取消', '售后完成', '退款申请中']
      let refundArr = [{
        num: 1,
        text: '未申请退款'
      },
        {
          num: 2,
          text: '退款申请中',
        },
        {
          num: 3,
          text: '退款申请成功',
        },
        {
          num: 4,
          text: '取消退款'
        },
        {
          num: 8,
          text: '待退货',
        },
        {
          num: 9,
          text: '退货中',
        },
      ]
      if (refundState === 1) {
        str = stateArr[orderState]
      } else {
        str = refundArr.filter(item => item.num === refundState)[0].text
      }
      return str
      // BE_OVERDUE(0,"已过期"),
      // WAIT_PAYMENT(1,"待付款"),
      // WAIT_DELIVER(2,"待发货"),      可退款，并且refundState 1/4
      // WAIT_RECEIVING(3,"待收货"),  可退款  , 并且refundState 1/4                  确认收货等按钮加一层判断 refundState === 1， 再显示
      // COMPLETE(4,"已完成"),       可退款(7天无理由)
      // CANCEL(5,"交易取消"),
      // REFUND_SUCCESS(6,"售后完成")
      // REFUND_APPLY(7,"退款申请中"),

      // NO_REFUND(1,"未申请退款"),
      // REFUND_APPLY(2,"退款申请中"),
      // REFUND_SUCCESS(3,"退款申请成功"),
      // REFUND_ERROR(4,"拒绝退款"),
      // REFUND_CANCEL(5,"取消退款"),
      // WAIT_EXPRESS(8,"待退货"), ？
      // EXPRESSING(9,"退货中"); ？

    }
  }
}
</script>
<style>
.goodsPhoto {
  width: 60px;
  height: 60px;
}

.companyName {
  border: 1px solid #ccc;
  height: 40px;
  line-height: 40px;
  background: #fff;
  margin-top: 10px;
}

.red {
  color: #f00;
}

.orderDetailWrap {
  background-color: #fff;
  padding: 20px;
}

.orderPayBox {
  text-align: right;
  font-size: 13px;
  margin: 20px;
}

.orderPayBox > div {
  padding: 5px 0;
}
</style>
