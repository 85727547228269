import httpUtil from "@/utils/httpUtil";

/**获取订单列表 */
export const selectOrdersInfoPc = async params => httpUtil.post("/orders/selectOrdersInfoPc", params)

/**修改订单状态 */
export const updateOrderState = async params => httpUtil.post("/orders/updateOrderState", params)

/***确认发货 不用了***/
export const fetchConfirmDeliver = async params => httpUtil.post("/orders/updateOrderStatePc", params)

/**修改订单状态 */
export const updateRefundState = async params => httpUtil.post("/orders/updateOrderStatePc", params)

/** 删除订单 */
export const delOrders = async params => httpUtil.post("/orders/delOrders", params)

/** 导出订单 */
export const exportOrdersInfoPc = async params => httpUtil.post("/orders/exportOrdersInfoPc", params)

/**确认发货***/
export const fetchSendOut = async params => httpUtil.post("/orders/sendOut", params)


/***查看退款订单详情，处理退款**/
export const fetchGetRefundDetail = async orderId => httpUtil.get(`/orders/refundDetail/${orderId}`)

/**拒绝退款***/
export const fetchRefuseRefund = async params => httpUtil.post("/orders/refuseRefund", params)

/**同意退款 */
export const fetchAgreeRefund = async params => httpUtil.post("/orders/agreeRefund", params)

/***查看退款日志 */
export const fetchGetRefundLog = async orderRefundId => httpUtil.get(`/orders/refundLogList/${orderRefundId}`)

/****商家收货 */
export const fetchBusinessReceive = async params => httpUtil.post(`/orders/finishRefund`, params)

/****订单详情 */
export const fetchOrderDetail = async params => httpUtil.get('/orders/detail', params)


export const fetchOrderEvaluateList = async params => httpUtil.get('/order/evaluate', params)

export const fetchOrderEvaluateDetail = async params => httpUtil.get('/order/evaluate/detail', params)

export const fetchOrderEvaluateAnswer = async params => httpUtil.post('/order/evaluate/answer', params)

export const fetchAddAddr = async params => httpUtil.post('/personal/address', params)

export const fetchEditAddr = async params => httpUtil.put('/personal/address', params)

export const fetchGetAddr = async params => httpUtil.post('/personal/getAddress', params)






