<template>
  <el-dialog
  :title="title"
  :visible.sync="dialogVisible"
  :append-to-body="true"
  width="60%"
  >
  <div>
    <el-form
          :model="ruleForm"
          :rules="rules"
          ref="addrRuleForm"
          label-width="100px"
          class="ruleForm"
        >
      <el-form-item label="收货人姓名" prop="receiveName">
        <el-input v-model="ruleForm.receiveName" placeholder="请输入收货人姓名"></el-input>
      </el-form-item>

      <el-form-item label="收货人电话" prop="receivePhone">
        <el-input v-model="ruleForm.receivePhone" placeholder="请输入收货人电话"></el-input>
      </el-form-item>

      <el-form-item label="省市区" prop="selectedOptions">
        <el-cascader class="cascaderSelect" size="large" :options="options" v-model="ruleForm.selectedOptions" @change="handleChange" placeholder="请选择省市区" clearable></el-cascader>
      </el-form-item>

      <el-form-item label="详细地址" prop="address">
        <el-input v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <!-- <el-form-item label="详细地址" prop="address">
        <Map ref="myMap" @updateLocation="updateLocation" />
        <el-input v-model="ruleForm.address" placeholder="请输入详细地址" style="opacity: 0;"></el-input>
      </el-form-item> -->

    </el-form>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="closeDialog">取 消</el-button>
    <el-button type="primary" @click="submitForm">确 定</el-button>
  </span>
</el-dialog>
</template>
<script>
import { 
    provinceAndCityData, 
    regionData,
    provinceAndCityDataPlus, 
    regionDataPlus, 
    CodeToText, 
    TextToCode 
} from 'element-china-area-data'
// import Map from './aliMap.vue'
import {
  fetchAddAddr,
  fetchEditAddr,
  fetchGetAddr
} from "@/api/order";
export default {
  data() {
    return {
      dialogVisible: false,
      title: '添加商家地址',
      mode: 'add',
      options: regionData,

      addressId: '',
      ruleForm: {
        province: '',
        city: '',
        county: '',
        address: '',
        coordinate: {
          lng: '',
          lat: '',
        },
        receiveName: '',
        receivePhone: '',
        selectedOptions: [],
      },
      rules: {
        receiveName: [
            { required: true, message: '请输入收货人姓名', trigger: 'blur' },
        ],
        receivePhone: [
           { required: true, message: '请输入收货人电话', trigger: 'blur' },
           { pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        selectedOptions: [
            { required: true, message: '请选择省市区', trigger: ['blur','change'] },
        ],
        address: [
            { required: true, message: '请输入收货地址', trigger: 'blur' },
        ],
      }
    }
  },
  components: {
    // Map
  },
  methods: {
    submitForm() {
      this.$refs.addrRuleForm.validate((valid) => {
        if(valid) {
          if(this.mode === 'add') {
            let params = {
              companyId: JSON.parse(localStorage.getItem('info')).companyId,
              receiveName: this.ruleForm.receiveName,
              receivePhone: this.ruleForm.receivePhone,
              locationAddress: this.ruleForm.address,
              province: CodeToText[this.ruleForm.selectedOptions[0]],
              city: CodeToText[this.ruleForm.selectedOptions[1]],
              country: CodeToText[this.ruleForm.selectedOptions[2]],
              addressType: 2,
              detailedAddress: this.ruleForm.address,
              addressArr: this.ruleForm.selectedOptions,
            }
            fetchAddAddr(params).then(res => {
              if(res.code === 200) {
                this.$message.success('添加成功')
                this.closeDialog()
              }
            })
          }else if(this.mode === 'edit') {
            let params = {
              companyId: JSON.parse(localStorage.getItem('info')).companyId,
              addressId: this.addressId,
              receiveName: this.ruleForm.receiveName,
              receivePhone: this.ruleForm.receivePhone,
              locationAddress: this.ruleForm.address,
              province: CodeToText[this.ruleForm.selectedOptions[0]],
              city: CodeToText[this.ruleForm.selectedOptions[1]],
              country: CodeToText[this.ruleForm.selectedOptions[2]],
              addressType: 2,
              detailedAddress: this.ruleForm.address,
              addArr: this.ruleForm.selectedOptions,
            }
            fetchEditAddr(params).then(res => {
              if(res.code === 200) {
                this.$message.success('编辑成功')
                this.closeDialog()
              }
            })
          }
        }
      })
    },
    initData() {
      let params = {
        companyId: JSON.parse(localStorage.getItem('info')).companyId,
        addressType: 2
      }
      fetchGetAddr(params).then(res => {
        if(res && res.data && res.data.length) {
          let data = res.data[0]
          this.addressId = data.addressId
          this.feedbackForm(data)
          this.mode = 'edit'
        }else {
          this.mode = 'add'
          this.addressId = ''
        }
        if(this.mode === 'add') {
          this.title = '添加商家地址'
        }else if(this.mode === 'edit') {
          this.title = '修改商家地址'
        }
        this.dialogVisible = true
      })

    },
    openDialog() {
      this.initData()
    },
    // 数据回显
    feedbackForm(data) {
    this.ruleForm.province = data.province
    this.ruleForm.city = data.city
    this.ruleForm.county = data.country
    this.ruleForm.address = data.locationAddress
    this.ruleForm.coordinate = {
      lng: '',
      lat: '',
    }
    this.ruleForm.receiveName = data.receiveName
    this.ruleForm.receivePhone= data.receivePhone
    if(data.province && data.city && data.country) {
      let p = TextToCode[data.province].code
      let s = TextToCode[data.province][data.city].code
      let q = TextToCode[data.province][data.city][data.country].code
      this.ruleForm.selectedOptions = [p,s,q]
    }
    // this.ruleForm.selectedOptions = data.province && data.city && data.county ? [TextToCode[data.province].code, TextToCode[data.province][data.city].code, TextToCode[data.province][data.city][data.county].code] : []
    this.$nextTick(()=>{
      this.$refs.myMap.setData(this.ruleForm)
    })
    },
    resetForm() {
      this.ruleForm = {
        province: '',
        city: '',
        county: '',
        address: '',
        coordinate: {
          lng: '',
          lat: '',
        },
        receiveName: '',
        receivePhone: '',
        selectedOptions: [],
      }
    },
    closeDialog() {
      this.dialogVisible = false
      this.resetForm()
    },
    handleChange(e) {
      console.log(e)
    },
    updateLocation(Addr) {
        this.ruleForm.address = Addr.orgAddr
        this.ruleForm.coordinate.lng = Addr.lon
        this.ruleForm.coordinate.lat = Addr.lat
        this.ruleForm.province =  Addr.province
        this.ruleForm.city = Addr.city
        this.ruleForm.county =  Addr.district
    },
  }
}
</script>
<style scoped>
.cascaderSelect{
  width: 300px;
}
</style>