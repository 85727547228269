import { formatAmount} from "../utils/utils";
export  default {
  data(){
    return {
      
    }
  },
  filters:{
    formatAmount(amount){
      return formatAmount(amount)
    }
  }
}
