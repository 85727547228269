<template>
  <div class="orderList-view view pa24" v-loading="exloading">
    <el-row :gutter="24">
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          <span>订单编号：</span>
          <el-input placeholder="请输入订单编号" v-model="orderNum" />
        </div>
      </el-col>
      <el-col :xl="6" :lg="6">
        <!-- <div class="d-flex align-items-center mt10 nowrap">
          下单时间：
          <el-date-picker
            style="width: 100%"
            v-model="pickerValue"
            align="right"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          />
        </div> -->
        <div class="d-flex align-items-center mt10 nowrap">
        下单时间：
          <el-date-picker
            v-model="orderTimeRange"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            >
          </el-date-picker>
        </div>
       </el-col>
       <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          来源：
          <el-select
            v-model="orderSource"
            placeholder="请选择"
          >
            <el-option
              v-for="item in orderSourceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
        </el-select>
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          类型：
          <el-select
            v-model="orderTypeValue"
            placeholder="请选择"
          >
            <el-option
              v-for="item in orderType"
              :key="item.label"
              :label="item.text"
              :value="item.label"
            >
            </el-option>
        </el-select>
        </div>
      </el-col>
      <el-col :xl="24" :lg="24">
        <div class="flex-a-b-c pt10">
          <div>
            订单状态：
            <el-radio
              style="margin-right: 0"
              v-model="orderStatusValue"
              :label="item.label"
              v-for="(item, index) in orderStatus"
              :key="index"
              border
            >
              {{ item.text }}
            </el-radio>
          </div>

        </div>
        <div class="btnBox">
            <el-button
              type="primary"
              class="ml10"
              @click="handleAddress"
              >地址管理</el-button >
            <el-button
              type="primary"
              class="ml10"
              @click="selectOrdersInfoPcAsync"
              >搜索</el-button >
              <el-button
              type="primary"
              class="ml10"
              @click="handleExportOrderList"
              >导出</el-button >
          </div>
      </el-col>
      <el-col :xl="24"> </el-col>
      <!-- <el-col :xl="24">
        订单类型：
        <el-radio
          style="margin-right: 0; margin-top: 10px"
          v-model="orderTypeValue"
          :label="item.label"
          v-for="(item, index) in orderType"
          :key="index"
          border
        >
          {{ item.text }}
        </el-radio>
      </el-col> -->
      <!-- <el-col :xl="6" :lg="6">
        <div class="d-flex align-items-center mt10 nowrap">
          <span>订单编号：</span>
          <el-input placeholder="请输入订单编号" />
        </div>
      </el-col> -->
    </el-row>
    <el-row :gutter="24">
      <!-- <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          用户姓名：
          <el-input placeholder="请输入用户姓名" />
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          联系方式：
          <el-input placeholder="请输入用户手机号" />
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          商品名称：
          <el-input placeholder="请输入商品名称" />
        </div>
      </el-col> -->
      <!-- <el-col :xl="12" :lg="12">
        <div class="d-flex align-items-center mt10 nowrap">
          <el-button type="primary" @click="selectOrdersInfoPcAsync">搜索</el-button>
        </div>
      </el-col> -->
    </el-row>
    <!-- :spanMethod="objectSpanMethod" -->
    <commonTable
      :tableData="orderData.list"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
          prop="ordersId"
          width="100"
          align="center"
          label="序号"
        />
        <el-table-column prop="ordersNo" align="center" label="订单编号" />
        <el-table-column
          prop="phone"
          align="center"
          width="120"
          label="收货人电话"
        />
        <el-table-column prop="orderPrice" align="center" label="订单金额">
          <template slot-scope="scope">
            {{ scope.row.orderPrice | formatAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="orderPrice" align="center" label="优惠金额">
          <template slot-scope="scope">
            {{ scope.row.subAmount | formatAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="orderPrice" align="center" label="实付金额">
          <template slot-scope="scope">
            {{ scope.row.payPrice | formatAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          show-overflow-tooltip
          label="下单时间"
        >
          <template slot-scope="scope"
            ><span>{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="assembleId" align="center" label="订单状态">
          <template slot-scope="scope"
            ><span>{{ scope.row | setState() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" align="center" label="结算状态">
          <template slot-scope="scope"
          ><span>{{ scope.row | setPayStatus() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" align="center" label="备注" />
        <el-table-column width="150px" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" class="cblue hover_pointer" @click="goOrderDetail(scope.row)">查看详情</el-button>
            <el-button
              type="text"
              class="cblue hover_pointer"
              v-if="scope.row.orderState == 0"
              @click="delExpireOrder(scope.row.ordersId)"
              :disabled="!hasPermi"
            >
              删除
            </el-button>
            <div v-else-if="scope.row.orderState == 1">
              <el-button
                type="text"
                class="cblue hover_pointer"
                @click="pay(scope.row.ordersId)"
                :disabled="!hasPermi"
              >
                支付
              </el-button>
              <el-button
                type="text"
                class="cblue hover_pointer"
                @click="onConfirmMsg(scope.row.ordersId, 5, 1)"
                :disabled="!hasPermi"
              >
                撤销订单
              </el-button>
            </div>

            <el-button
              type="text"
              class="cblue hover_pointer"
              v-else-if="
                scope.row.orderState == 2
              "
              @click="sendOut(scope.row)"
              :disabled="!hasPermi"
            >
              确认发货
            </el-button>

            <el-button
                type="text"
                class="cblue hover_pointer"
                v-else-if="
                scope.row.orderState === 3
              "
                @click="finishOrder(scope.row)"
            >
              确认收货
            </el-button>

            <span
              class="cblue hover_pointer"
              v-else-if="scope.row.orderState == 7 || (scope.row.orderState == 6 && scope.row.refundState === 4)"
              @click="goRefundDetail(scope.row)"
            >
             退款详情
            </span>
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <!--确认发货弹窗-->
    <el-dialog
      title="确认发货"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
       width="60%"
      >
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="快递公司" prop="express">
          <el-select v-model="ruleForm.express" placeholder="请选择快递公司">
            <el-option v-for="item in fastMail" :key="item.name" :value="item.name" :label="item.name"></el-option>
            <!-- <el-option label="邮政" value="邮政"></el-option>
            <el-option label="韵达" value="韵达"></el-option>
            <el-option label="中通" value="中通"></el-option> -->
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="快递编码" prop="expressCode">
          <el-input v-model="ruleForm.expressCode"></el-input>
        </el-form-item> -->
        <el-form-item label="快递单号" prop="expressNum">
          <el-input v-model="ruleForm.expressNum"></el-input>
        </el-form-item>
      </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
        <el-button type="primary" @click="confirmSendOut">确 定</el-button>
      </span>
    </el-dialog>
    <refund-detail-component ref="refund" @close="closeRefundDetail" :dialog-show="refundDialogShow"></refund-detail-component>
    <order-detail-component ref="detail" @close="detailShow=false" :dialog-show="detailShow"></order-detail-component>
    <!--地址管理-->
    <AddrDialog ref="addrDialog"></AddrDialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import AddrDialog from './AddrDialog'
import {
  selectOrdersInfoPc,
  updateOrderState,
  delOrders,
  updateRefundState,
  exportOrdersInfoPc,
  fetchConfirmDeliver,
  fetchSendOut,
  fetchRefuseRefund,
  fetchAgreeRefund,
  fetchGetRefundDetail,
  fetchGetRefundLog,
} from "@/api/order";
import httpUtil from "@/utils/httpUtil";
import { getDataTimeSec } from "@/utils";
import { get, post } from "../../../utils/request";
import { formatAmount } from '@/utils/utils'
import good from "@/mixins/good";
import RefundDetailComponent from "@/components/Order/RefundDetailComponent";
import OrderDetailComponent from "@/components/Order/OrderDetailComponent";

export default {
  name: "orderList",
  mixins: [good],
  components: {
    commonTable,RefundDetailComponent,OrderDetailComponent,AddrDialog
  },
  data() {
    return {
      refundDialogShow: false,
      detailShow: false,
      exloading: false,
      hasPermi: true,
      pickerValue: "", //下单时间
      searchCondition: "", //搜索时间
      spanArr: [], //二维数组，用于存放单元格合并规则
      position: 0, //用于存储相同项的开始index
      loading: false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      start: "",
      end: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      orderTimeRange: "", // 下单时间范围
      orderTypeValue: "all", //订单类型
      orderSource: 'all', // 订单来源
      orderNum: '', // 订单编号
      orderStatusValue: "all", //订单状态
      orderSourceOptions: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: '8',
          label: '防伪码'
        },
        {
          value: '1',
          label: '防伪码名片'
        },
      ],
      orderStatus: [
        //订单状态
        { text: "全部订单", label: "all" },
        { text: "待付款", label: 1 },
        { text: "待发货", label: 2 },
        { text: "待收货", label: 3 },
        { text: "已完成", label: 4 },
        { text: "交易取消", label: 5 },
        { text: "售后完成", label: 6 },
        { text: "退款申请中", label: 7 },
      ],
      orderType: [ //  1 分销订单 2 普通订单 3 拼团订单 4秒杀订单
        //订单类型
        { text: "全部订单", label: "all" },
        { text: "分销订单", label: "1" },
        { text: "普通订单", label: "2" },
        { text: "拼团订单", label: "3" },
        { text: "秒杀订单", label: "4" },
      ],
      tableData: [],
      orderData: {},
      pageNum: 1,
      pageSize: 10,
      dialogVisible: false, // 确认发货弹窗
      ruleForm: {
        express: '',
        // expressCode: '',
        expressNum: '',
        orderId: null,
      },
      rules: {
        express: [
          { required: true, message: '请选择快递', trigger: 'change' }
        ],
        // expressCode: [
        //   { required: true, message: '请输入快递编码', trigger: 'blur' },
        // ],
        expressNum: [
          { required: true, message: '请输入快递单号', trigger: 'blur' },
        ]
      },
      fastMail: [
        {
          name: '顺丰',
          eName: 'shunfeng'
        },
        {
          name: 'EMS',
          eName: 'ems'
        },
        {
          name: '邮政包裹',
          eName: 'youzhengguonei'
        },
        {
          name: '百世汇通',
          eName: 'huitongkuaidi'
        },
        {
          name: '申通',
          eName: 'shentong'
        },
        {
          name: '中通',
          eName: 'zhongtong'
        },
        {
          name: '圆通',
          eName: 'yuantong'
        },
        {
          name: '韵达',
          eName: 'yunda'
        },
        {
          name: '天天',
          eName: 'tiantian'
        },
        {
          name: '优速',
          eName: 'youshuwuliu'
        },
        {
          name: '京东',
          eName: 'jd'
        },
        {
          name: '宅急送',
          eName: 'zhaijisong'
        },
      ]
    };
  },
  created() {
    this.selectOrdersInfoPcAsync();
    this.rowspan(0, "orderNumber");
    this.rowspan(8, "nameOrtelephone");
    this.rowspan(9, "orderType");
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  watch: {
    pickerValue(val, old) {
      console.log(val);
      if (val == null) {
        return (this.searchCondition = "");
      }
      this.searchCondition = getDataTimeSec(val, "yy-mm-dd") + " 00:00:00";
      // this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
      // this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59"
      // console.log(this.start)
      // console.log(this.end)
    },
  },
  filters: {
    setState(item) {
      console.log('item', item.orderState)
      let str=''
      if(item.orderState < 6) {
        let state=[
          {
            state: 0,
            text: '已失效'
          },
          {
            state: 1,
            text: '待付款'
          },
          {
            state: 2,
            text: '待发货'
          },
          {
            state: 3,
            text: '待收货'
          },
          {
            state: 4,
            text: '已完成'
          },
          {
            state: 5,
            text: '订单取消'
          },
        ]
        str = state.filter(it => it.state === item.orderState)[0].text
      }else {
        if(item.refundState == 2) {
          str = '退款申请中'
        }else if(item.refundState == 3) {
          str = '退款成功'
        }else if(item.refundState == 4) {
          str = '拒绝退款'
        }else if(item.refundState == 5) {
          str = '取消退款'
        }else if(item.refundState == 8) {
          str = '待退货'
        }else if(item.refundState == 8) {
          str = '退货中'
        }
      }
      return str
    },
    setPayStatus(row){
      if (row.payStatus == 0){
        return '待结算'
      }else if (row.payStatus == 1){
        return '已结算'
      }else if (row.payStatus == 2){
        return '结算失败'
      }else {
        return '--'
      }
    },
    setDate(date) {
      return getDataTimeSec(date);
    },
    formatAmount(amount){
      return formatAmount(amount)
    },
    applyTypeText(type) {
      let text = ''
      if(type === 1) {
        text = '仅退款'
      }else if(type === 2) {
        text = '退货退款'
      }
      return text
    },
    receivedText(received) {
      let text = ''
      if(received) {
        text = '是'
      }else{
        text = '否'
      }
      return text
    },
  },
  methods: {
    handleAddress() {
      this.$refs.addrDialog.openDialog()
    },
    closeRefundDetail() {
      this.refundDialogShow=false
      this.selectOrdersInfoPcAsync()
    },
    // 订单导出
    handleExportOrderList() {

      let this_ = this
      this.$confirm('是否确认导出当前筛选的订单数据吗?', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
      }).then(function () {
          this_.exloading = true
          this_.pageNum = 1
          this_.pageSize = this_.total
          this_.selectOrdersInfoPcAsync()
          // this_.exportExcel(this_.orderData.list)
      })
    },
    exportExcel(arr) {
        require.ensure([], () => {
            const { export_json_to_excel } = require('../../../excel/Export2Excel.js');
            console.log(export_json_to_excel);
            const tHeader = ['订单编号', '收货人电话', '订单金额', '优惠金额','应付金额','下单时间']; //
            // 上面设置Excel的表格第一行的标题
            const filterVal = ['ordersNo', 'phone', 'orderPrice', 'subAmount', 'payPrice', 'createTime']; //
            // 上面的index、nickName、name是tableData里对象的属性
            const list = arr.map(item => {
                item.orderPrice= formatAmount(item.orderPrice)
                item.subAmount= formatAmount(item.subAmount)
                item.payPrice= formatAmount(item.payPrice)
                return item
            });  //把data里的tableData存到list
            const data = this.formatJson(filterVal, list);
            var titName =  "订单数据_" + new Date().getTime()
            this.exloading = false
            export_json_to_excel(tHeader, data, titName);
        })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map((j) => {
          return v[j]
      }))
    },
    //导出
    exportOrder() {
      let data = {
        orderState: "",
      };
      exportOrdersInfoPc(data)
        .then((res) => {
          let blob = new Blob(["\ufeff", res], {
            type: "text/csv,charset=UTF-8",
          });

          if (!!window.ActiveXObject || "ActiveXObject" in window) {
            window.navigator.msSaveOrOpenBlob(blob, "fileName.csv");
          } else {
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "fileName.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          let link = document.createElement("a");
          let url = window.URL.createObjectURL(res);
          link.href = url;
          link.download = "订单列表.xls";

          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rowspan(idx, prop) {
      this.spanArr[idx] = [];
      this.position = 0;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr[idx].push(1);
          this.position = 0;
        } else {
          if (this.tableData[index][prop] === this.tableData[index - 1][prop]) {
            this.spanArr[idx][this.position] += 1; //有相同项
            this.spanArr[idx].push(0); // 名称相同后往数组里面加一项0
          } else {
            this.spanArr[idx].push(1); //同列的前后两行单元格不相同
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 8, 9].includes(columnIndex)) {
        const _row = this.spanArr[columnIndex][rowIndex];
        const _col = _row > 0 ? 1 : 0;
        // console.log('第'+rowIndex+'行','第'+i+'列','rowspan:'+_row,'colspan:'+_col)
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    /**@method 获取订单列表 */
    async selectOrdersInfoPcAsync() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // ordersNo: this.orderNum ? this.orderNum : '',
        // startTime: this.orderTimeRange ? this.orderTimeRange[0] : '',
        // endTime: this.orderTimeRange ? this.orderTimeRange[1] : '',
        // source: this.orderSource ? this.orderSource : '',
        // orderState: this.orderStatusValue ? this.orderStatusValue : '',
        // orderType: this.orderTypeValue ? this.orderTypeValue : ''
      };
      if(this.orderNum) {
        data.ordersNo=this.orderNum
      }
      if(this.orderTimeRange) {
        data.startTime=this.orderTimeRange[0]
        data.endTime=this.orderTimeRange[1]
      }
      if(this.orderSource && this.orderSource!== 'all') {
        data.source = this.orderSource
      }
      if(this.orderStatusValue && this.orderStatusValue!== 'all') {
        data.orderState = this.orderStatusValue
      }
      if(this.orderTypeValue && this.orderTypeValue!== 'all') {
        data.orderType=this.orderTypeValue
      }
      get("/orders/orderListPc", data).then((res) => {
        this.orderData = res;
        this.total = res.total
        if(this.exloading) {
          this.exportExcel(this.orderData.list)
        }
      });

    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.selectOrdersInfoPcAsync();
    },
    pay(orderId) {
      httpUtil.post("/orders/paySuccess", { orderId: orderId }).then((res) => {
        this.$message({
          type: "success",
          message: "支付成功",
        });
        this.selectOrdersInfoPcAsync();
      });
    },
    //删除订单
    delExpireOrder(id) {
      this.$confirm("确定要删除这个订单吗？", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          delOrders({ ordersId: id }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.selectOrdersInfoPcAsync();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //修改订单状态
    onConfirmMsg(ordersId, state, type) {
      let obj = {
        1: {
          5: "确定要撤销该订单吗？",
          3: "是否确定发货？",
        },
        2: {
          1: "是否拒绝退款？",
          3: "是否确定退款",
        },
      };
      let text = obj[type][state];

      this.$confirm(text, "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          if (type == 1) {
            // this.changeOrderState(ordersId, state);
            this.changeOrderState2(ordersId, state)
          } else {
            this.changeRefund(ordersId, state);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeOrderState(id, state) {
      updateOrderState({
        orderState: state,
        ordersId: id,
        refundState: state,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.selectOrdersInfoPcAsync();
      });
    },
    changeOrderState2(id, state) {
      fetchConfirmDeliver({
        orderState: state,
        ordersId: id,
        refundState: state,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.selectOrdersInfoPcAsync();
      });
    },
    changeRefund(id, state) {
      updateRefundState({
        orderState: state,
        ordersId: id,
        refundState: state,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.selectOrdersInfoPcAsync();
      });
    },
    // 确认发货
    sendOut(data) {
      this.dialogVisible = true
      this.ruleForm.orderId = data.ordersId
    },
    finishOrder(data){
      post('/orders/finishOrder',{orderId:data.ordersId}).then(res=>{
        this.selectOrdersInfoPcAsync();
      })
    },
    resetForm(formName) {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false
    },
    confirmSendOut() {
      let v=this
      this.$refs.ruleForm.validate((valid) => {
        if(valid) {
          v.ruleForm.expressCode = this.fastMail.filter(item => item.name === v.ruleForm.express)[0].eName
          fetchSendOut(v.ruleForm).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.dialogVisible = false
            this.selectOrdersInfoPcAsync();
          });
        }
      })

    },
    // 跳转到退款订单详情页
    goRefundDetail(data) {
       this.refundDialogShow=true;
       this.$refs.refund.init(data.ordersId)
      //this.$router.push('/orderRefundDetail?ordersId='+data.ordersId)
    },
    goOrderDetail(data) {
      this.detailShow=true;
      this.$refs.detail.init(data.ordersId)
    },
    // 拒绝退款
    handleRefuseRefund(refundId){
      // this.handleOrderId = data.ordersId
      // this.remarkVisible = true
      // this.remarkDialogType = 0
      // this.remarkDialogTitle = '拒绝退款'
      let params = {
        orderRefundId: refundId,
        // remark: this.orderRemark,
      }
      fetchRefuseRefund(params).then(res => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.closeSeeRefundDialog()
        this.selectOrdersInfoPcAsync();
      })
    },
    // 同意退款
    handleAgreeRefund(refundId) {
      // this.handleOrderId = data.ordersId
      // this.remarkVisible = true
      // this.remarkDialogType = 1
      // this.remarkDialogTitle = '同意退款'
      let params = {
          orderRefundId: refundId,
          // remark: this.orderRemark,
        }
        fetchAgreeRefund(params).then(res => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.closeSeeRefundDialog()
          // this.closeRemarkDialog()
          this.selectOrdersInfoPcAsync();
        })
    },
    closeSeeRefundDialog() {
      this.seeRefundVisible = false
    },

    // closeRemarkDialog() {
    //   this.orderRemark = ''
    //   this.remarkVisible = false
    // },
    // confirmRemark() {
    //   if(this.remarkDialogType === 0) { // 拒绝退款
    //     let params = {
    //       orderRefundId: this.handleOrderId,
    //       remark: this.orderRemark,
    //     }
    //     fetchRefuseRefund(params).then(res => {
    //       this.$message({
    //         type: "success",
    //         message: "操作成功",
    //       });
    //       this.closeRemarkDialog()
    //       this.selectOrdersInfoPcAsync();
    //     })
    //   }else if(this.remarkDialogType === 1) { //同意退款
    //     let params = {
    //       orderRefundId: this.handleOrderId,
    //       remark: this.orderRemark,
    //     }
    //     fetchAgreeRefund(params).then(res => {
    //       this.$message({
    //         type: "success",
    //         message: "操作成功",
    //       });
    //       this.closeRemarkDialog()
    //       this.selectOrdersInfoPcAsync();
    //     })
    //   }

    // },
    // 查看申请退款
    handleSeeRefund(data) {
      let v = this
      this.handleOrderId = data.ordersId
      fetchGetRefundDetail(this.handleOrderId).then(res => {
        if(res.data) {
          v.refundTableData = res.data
          this.seeRefundVisible = true
        }
      })
    },
    // 退款日志
    handleSeeRefundLog(data) {
      let v = this
      this.refundLogVisible =  true
      this.seeRefundVisible = false
      fetchGetRefundLog(data.id).then(res => {
      })
    },
  },

};
</script>

<style scoped lang='scss'>
.orderList-view {
  padding-top: 14px;
}
.btnBox{
  padding-top: 20px;
  text-align: right;
}
</style>
